import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Reservation from './views/pages/reservation';
import ReservationPayment from './views/pages/reservation/ReservationPayment';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// const Airport = React.lazy(() => import('./views/pages/airport'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/reservation/payment"
              name="Payment Page"
              render={(props) => <ReservationPayment />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Reservation Page" render={(props) => <Reservation />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
