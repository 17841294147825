import React from 'react';
import { Map } from '../../../components/Reservation/Map';
import { ReservationMultiStepForm } from '../../../components/Reservation/ReservationMultiStepForm';
export default function Reservation() {
  return (
    <>
      <Map />
      <ReservationMultiStepForm />
    </>
  );
}
