import React from 'react';
import { PropTypes } from 'prop-types';

import { MapContainer, Marker, TileLayer, Tooltip, useMapEvent } from 'react-leaflet';
import { useReservationForm } from 'src/contexts/ReservationFormContextProvider';

const MapEvent = () => {
  const { origin, destination, setOrigin, setDestination, getAddressName, step, goToStep } =
    useReservationForm();
  useMapEvent('click', async (eventPoint) => {
    if (!origin && step === 2) {
      setOrigin({ latlng: eventPoint.latlng, address: await getAddressName(eventPoint.latlng) });
      goToStep(1);
      return;
    }

    if (!destination && step === 3) {
      setDestination({
        latlng: eventPoint.latlng,
        address: await getAddressName(eventPoint.latlng),
      });
      goToStep(1);
      return;
    }

    if (!origin) {
      setOrigin({ latlng: eventPoint.latlng, address: await getAddressName(eventPoint.latlng) });
      goToStep(1);
      return;
    }

    if (!destination) {
      setDestination({
        latlng: eventPoint.latlng,
        address: await getAddressName(eventPoint.latlng),
      });
      goToStep(1);
      return;
    }
  });
  return <></>;
};

export const Map = () => {
  const { origin, destination, setOrigin, setDestination, getAddressName, step } =
    useReservationForm();
  const defaultMapCoordenade = { lat: 14.9414817, lng: -23.4845572 };

  const updateOrigin = async (latlng) => {
    if (latlng.lat === origin.latlng.lat && latlng.lng === origin.latlng.lng) {
      return;
    }
    setOrigin({ latlng: latlng, address: await getAddressName(latlng) });
  };

  const updateDestination = async (latlng) => {
    if (latlng.lat === destination.latlng.lat && latlng.lng === destination.latlng.lng) {
      return;
    }
    setDestination({ latlng: latlng, address: await getAddressName(latlng) });
  };

  /*const ZoomToBound = ({ bounds }) => {
    const map = useMap();
    if (bounds) {
      map.fitBounds(bounds, { padding: [50, 50] });
      map.setZoom(12);
    }
    return null;
  };*/

  return (
    <div style={{ top: 0, left: 0, right: 0, bottom: 0 }} className="position-absolute">
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={defaultMapCoordenade}
        zoom={12}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {origin && (
          <Marker
            draggable={step === 1 || step === 2}
            key="origin"
            title="Origem"
            alt="Origem"
            position={origin.latlng}
            eventHandlers={{ dragend: (e) => updateOrigin(e.target.getLatLng()) }}
          >
            <Tooltip direction="top" offset={[-15, -15]} opacity={1} permanent>
              Origem
            </Tooltip>
          </Marker>
        )}
        {destination && (
          <Marker
            draggable={step === 1 || step === 3}
            key="destination"
            title="Destino"
            alt="Destino"
            position={destination.latlng}
            eventHandlers={{ dragend: (e) => updateDestination(e.target.getLatLng()) }}
          >
            <Tooltip direction="top" offset={[-15, -15]} opacity={1} permanent>
              Destino
            </Tooltip>
          </Marker>
        )}

        {/* {origin && <ZoomToBound bounds={[origin.latlng]} />}
        {destination && <ZoomToBound bounds={[destination.latlng]} />} */}
        <MapEvent />
      </MapContainer>
    </div>
  );
};

//props validation
Map.propTypes = {
  origin: PropTypes.any,
  destination: PropTypes.any,
  destinationPoint: PropTypes.any,
  polyline: PropTypes.any,
};
