export const formatDate = (date) => {
  const jsDate = new Date(date);
  return jsDate
    .toLocaleString('pt', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replaceAll('.', '')
    .replaceAll(' de ', ' ');
};

let message_id;
export const nextMessageID = () => {
  return ++message_id;
};

export const getStatusColor = (status) => {
  if (!status) {
    return 'light';
  }

  if (status === 'aceite') {
    return 'success';
  }
  if (status === 'pendente') {
    return 'warning';
  }
  if (status === 'incomplete') {
    return 'danger';
  }

  if (status === 'em curso') {
    return 'info';
  }

  return 'dark';
};

export const addUserToLocalStorage = (user) => {
  const json = JSON.stringify(user);
  return localStorage.setItem('user', json);
};

export const sleep = async (sleepms) => new Promise((resolve) => setTimeout(resolve, sleepms));

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};
