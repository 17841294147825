import React, { useCallback, useEffect } from 'react';
import {
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from '@coreui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useReservationForm } from '../../../contexts/ReservationFormContextProvider';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Gestures } from 'react-gesture-handler';
import { ResumeItem } from './ResumeItem';
import { Step } from './Step';
import { useCookies } from 'react-cookie';
import { getPrice, getReservationTicket, isIsland, isLocation } from 'src/servers/Api';
import { pad, randomIntFromInterval, sleep } from 'src/utils';
import { NHATAXI_BASE_URL } from 'src/servers/Api';

export function ReservationMultiStepForm() {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['client_info']);

  const {
    suggestions: { status: autoCompleteStatus, data: autoCompleteData },
    setValue: setAutoCompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { componentRestrictions: { country: 'cv' } },
  });

  const {
    date,
    step,
    handleDate,
    isDateInvalid,
    origin,
    destination,
    time,
    handleTime,
    isTimeInvalid,
    additionalObservations,
    setAdditionalObservations,
    clearState,
    email,
    setEmail,
    name,
    setName,
    lastName,
    setLastName,
    goToStep,
    price,
    setPrice,
    contact,
    setContact,
    setOrigin,
    setDestination,
    setReservationTicket,
    referralLink,
    reservationTicket,
  } = useReservationForm();

  const [validEmail, setValidEmail] = useState(true);
  const [updatingPrice, setUpdatingPrice] = useState(false);
  const [validOrigin, setValidOrigin] = useState(true);
  const [validDestination, setValidDestination] = useState(true);
  const [updatingLocation, setUpdationLocation] = useState(false);
  const [bottomsheetCollapse, setBottomsheetCollapse] = useState(false);
  const [originAddress, setOriginAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [selectedOriginAddress, setSelectedOriginAddress] = useState('');
  const [selectedDestinationAddress, setSelectedDestinationAddress] = useState('');

  const isValidEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const getTimestamp = (date, time) =>
    Math.floor(new Date(`${date}T${time}-01:00`).getTime() / 1000);

  const updatePrice = async (date, time) => {
    if (date && time) {
      const response = await getPrice(
        origin.latlng.lat,
        origin.latlng.lng,
        destination.latlng.lat,
        destination.latlng.lng,
        getTimestamp(date, time),
      );
      setPrice(response.data.result);
      setUpdatingPrice(false);
    }
  };

  const handleEmailChange = useCallback(
    (email) => {
      if (isValidEmail(email)) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }

      setEmail(email);
    },
    [setEmail],
  );

  useEffect(() => {
    setOriginAddress(origin?.address?.display_name || '');
    setSelectedOriginAddress(origin?.address?.display_name || '');
    if (origin) {
      setUpdationLocation(true);
      isLocation(origin.latlng.lat, origin.latlng.lng, 'cidade da praia').then((response) => {
        setUpdationLocation(false);
        setValidOrigin(response.data.result);
      });
    }
  }, [origin]);

  useEffect(() => {
    setDestinationAddress(destination?.address?.display_name || '');
    setSelectedDestinationAddress(destination?.address?.display_name || '');

    if (destination) {
      setUpdationLocation(true);
      isIsland(destination.latlng.lat, destination.latlng.lng, 'santiago').then((response) => {
        setUpdationLocation(false);
        setValidDestination(response.data.result);
      });
    }
  }, [destination]);

  useEffect(() => {
    if (cookies.client_info && (!name || !lastName || !email || !contact)) {
      setName(cookies.client_info.name);
      setLastName(cookies.client_info.lastName);
      handleEmailChange(cookies.client_info.email);
      setContact(cookies.client_info.contact);
    }
  }, [
    contact,
    cookies,
    email,
    lastName,
    name,
    setContact,
    setEmail,
    setLastName,
    setName,
    handleEmailChange,
  ]);

  const getOrderSummary = () => (
    <CContainer>
      <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
        <div>
          <span>Total a pagar</span>
          <h2>{price} CVE</h2>
        </div>
      </div>
      <CRow xs={{ cols: 1 }} md={{ cols: 2 }} className="resume-container">
        <ResumeItem title="Origem" value={selectedOriginAddress} />
        <ResumeItem title="Destino" value={selectedDestinationAddress} />
        <ResumeItem title="Data" value={date} />
        <ResumeItem title="Hora" value={time} />
        <ResumeItem title="E-mail" value={email} />
        <ResumeItem title="Tel." value={contact} />
        <ResumeItem title="Observações" value={additionalObservations} />
      </CRow>
    </CContainer>
  );

  //react email validation
  const handleLocationChange =
    ({ description }) =>
    () => {
      getGeocode({ address: description }).then((results) => {
        const latlng = getLatLng(results[0]);
        if (step === 2) {
          setOrigin({ latlng: latlng, address: { display_name: description } });
        } else if (step === 3) {
          setDestination({
            latlng: latlng,
            address: { display_name: description },
          });
        }
      });
      goToStep(1);
      clearSuggestions();
    };

  const renderSuggestions = () =>
    autoCompleteData.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <button
          key={place_id}
          type="button"
          className="list-group-item list-group-item-action"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          onClick={handleLocationChange(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </button>
      );
    });

  const fetchReservationTicket = async () => {
    const start = Date.now();
    const wait = randomIntFromInterval(1500, 1800);
    try {
      const { data: reservation } = await getReservationTicket(getTimestamp(date, time));
      await sleep(Math.max(0, wait - (Date.now() - start)));
      if (reservation.success !== true) {
        throw Error(reservation);
      }

      setReservationTicket(reservation.response.uuid);
      goToStep(6);
    } catch (e) {
      await sleep(Math.max(0, wait - (Date.now() - start)));
      goToStep(4);
    }
  };

  return (
    <Gestures
      recognizers={{
        Tap: {
          events: {
            tap: () => setBottomsheetCollapse(false),
          },
        },
        Swipe: {
          events: {
            swipedown: () => setBottomsheetCollapse(true),
            swipeup: () => setBottomsheetCollapse(false),
          },
        },
      }}
    >
      <div className="reservation-form-container position-absolute">
        <CForm className="multi-step-container">
          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={1}
            title="Dados da Localização"
            first
            onClick={() => {
              goToStep(4);
              updatePrice(date, time);
            }}
            disabled={
              !origin || !destination || updatingLocation || !validOrigin || !validDestination
            }
          >
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} className="mt-4">
              <CCol>
                <CFormLabel>Origem</CFormLabel>
                <CFormInput
                  value={selectedOriginAddress}
                  onClick={() => goToStep(2)}
                  onChange={() => {}}
                  invalid={!validOrigin}
                />
              </CCol>
              <CCol>
                <CFormLabel>Destino</CFormLabel>
                <CFormInput
                  value={selectedDestinationAddress}
                  onClick={() => goToStep(3)}
                  onChange={() => {}}
                  invalid={!validDestination}
                />
              </CCol>
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={2}
            title="Origem"
            onBack={() => {
              goToStep(1);
              clearSuggestions();
              setOriginAddress(selectedOriginAddress);
            }}
            disabled={!origin || !destination}
          >
            <CRow className="mt-4">
              <CCol>
                <CFormInput
                  value={originAddress}
                  autoFocus={true}
                  onChange={(e) => {
                    setOriginAddress(e.target.value);
                    setAutoCompleteValue(e.target.value);
                  }}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>{autoCompleteStatus === 'OK' && renderSuggestions()}</CCol>
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={3}
            title="Destino"
            onBack={() => {
              goToStep(1);
              clearSuggestions();
              setDestinationAddress(selectedDestinationAddress);
            }}
            disabled={!origin || !destination}
          >
            <CRow className="mt-4">
              <CCol>
                <CFormLabel>Destino</CFormLabel>
                <CFormInput
                  value={destinationAddress}
                  autoFocus={true}
                  onChange={(e) => {
                    setDestinationAddress(e.target.value);
                    setAutoCompleteValue(e.target.value);
                  }}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>{autoCompleteStatus === 'OK' && renderSuggestions()}</CCol>
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={4}
            title="Dados da Reserva"
            nextButtonLable={!updatingPrice ? `Continuar (${price} ECV)` : undefined}
            onClick={() => {
              goToStep(5);
              fetchReservationTicket();
            }}
            onBack={() => goToStep(1)}
            disabled={isDateInvalid || isTimeInvalid || !time || !date || updatingPrice}
          >
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} className="mt-4">
              <CCol>
                <CFormLabel>Data</CFormLabel>
                <CFormInput
                  type="date"
                  id="date"
                  invalid={isDateInvalid}
                  className="mb-2"
                  value={date || ''}
                  onChange={(e) => {
                    const dateValue = e.target.value;
                    handleDate(dateValue);
                    updatePrice(dateValue, time);
                  }}
                />
              </CCol>
              <CCol>
                <CFormLabel>Hora</CFormLabel>
                <CFormInput
                  type="time"
                  id="time"
                  invalid={isTimeInvalid}
                  value={time}
                  onChange={(e) => {
                    const values = e.target.value.split(':');
                    const value = `${values[0]}:${pad(Math.floor(values[1] / 10) * 10, 2)}`;
                    setUpdatingPrice(true);
                    handleTime(value);
                    updatePrice(date, value);
                  }}
                  step={600}
                />
              </CCol>
            </CRow>
            <CRow className="mt-2">
              <CCol>
                <CFormLabel>Observações</CFormLabel>
                <CFormInput
                  onChange={(e) => setAdditionalObservations(e.target.value)}
                  value={additionalObservations}
                />
              </CCol>
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={5}
            title="Verificar Agenda"
            noaction
          >
            <CRow className="mt-4">
              <CFormLabel>Por favor, aguarde. A verificar disponibilidade...</CFormLabel>
            </CRow>
            <CRow className="mt-4 mb-4 justify-content-center">
              <CSpinner style={{ width: '5rem', height: '5rem' }} />
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={6}
            title="Dados do Passageiro"
            disabled={
              !name || !lastName || !email || !validEmail || !contact || contact.trim().length !== 7
            }
            onClick={() => {
              goToStep(7);
              setCookie('client_info', { name, lastName, email, contact });
            }}
            onBack={() => {
              goToStep(4);
            }}
          >
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }}>
              <CCol className="mt-4">
                <CFormLabel>Nome</CFormLabel>
                <CFormInput
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  valid={!!name}
                />
              </CCol>
              <CCol className="mt-4">
                <CFormLabel>Apelido</CFormLabel>
                <CFormInput
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                  valid={!!lastName}
                />
              </CCol>

              <CCol className="mt-4">
                <CFormLabel>E-mail</CFormLabel>
                <CFormInput
                  type="email"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  required
                  value={email}
                  invalid={!!email && !validEmail}
                  valid={!!email && validEmail}
                />
              </CCol>

              <CCol className="mt-4">
                <CFormLabel>Tel.</CFormLabel>
                <CFormInput
                  onChange={(e) => setContact(e.target.value)}
                  required
                  value={contact}
                  type="number"
                  valid={contact.trim().length === 7}
                />
              </CCol>
            </CRow>
          </Step>

          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={7}
            title="Resumo"
            nextButtonLable="Reservar Agora"
            onClick={() => {
              const paymentProcessorLink = `${NHATAXI_BASE_URL}/api/payment/reservation?price=${price}&originAddress=${encodeURIComponent(
                origin?.address?.display_name,
              )}&originLatitude=${origin?.latlng?.lat}&originLongitude=${
                origin?.latlng?.lng
              }&destinationAddress=${encodeURIComponent(
                destination?.address?.display_name,
              )}&destinationLatitude=${destination?.latlng?.lat}&destinationLongitude=${
                destination?.latlng?.lng
              }&reservationTicket=${reservationTicket}&passengerPhoneNumber=${contact}&passengerName=${name}&passengerLastName=${lastName}&passengerEmail=${email}&observation=${additionalObservations}&native=false&referralLink=${referralLink}&date=${date}&time=${time}&callbackURL=${encodeURIComponent(
                `${window.location.origin}/#/reservation/payment`,
              )}`;
              window.location = paymentProcessorLink;
            }}
          >
            {getOrderSummary()}
          </Step>
          <Step
            bottomsheetCollapse={bottomsheetCollapse}
            currentStep={step}
            formStep={8}
            title="Reserva Concluída!"
            nextButtonLable="Nova Reserva"
            last
            onClick={() => {
              goToStep(1);
              clearState();
            }}
          >
            {getOrderSummary()}
          </Step>
        </CForm>
        {(isTimeInvalid || isDateInvalid) && (
          <CToaster placement={'top-end'}>
            <CToast
              delay={10000}
              visible={true}
              color={'warning'}
              className="text-black align-items-center"
            >
              <div className="d-flex">
                <CToastBody>
                  Só é possível agendar um táxi com, no mínimo, 24 horas de antecedência
                </CToastBody>
                <CToastClose className="me-2 m-auto" white />
              </div>
            </CToast>
          </CToaster>
        )}
      </div>
    </Gestures>
  );
}
