import React from 'react';
import { CButton, CContainer } from '@coreui/react';
import { PropTypes } from 'prop-types';
import { useReservationForm } from '../../../contexts/ReservationFormContextProvider';

export function Step({
  formStep,
  currentStep,
  title,
  onClick,
  onBack,
  first,
  last,
  nextButtonLable,
  disabled,
  noaction,
  bottomsheetCollapse,
  children,
}) {
  const { goBack } = useReservationForm();

  return (
    <>
      {formStep === currentStep && (
        <CContainer>
          <h4>{title}</h4>
          {!bottomsheetCollapse && (
            <>
              {children}
              {!noaction && first && (
                <div className="mt-4 d-grid gap-2">
                  <CButton onClick={onClick} disabled={disabled}>
                    Continuar
                  </CButton>
                </div>
              )}

              {!noaction && !first && !last && (
                <div className="mt-4 d-grid d-md-flex gap-2 justify-content-md-end">
                  <CButton onClick={onBack ?? goBack} color="secondary">
                    Voltar
                  </CButton>
                  {onClick && (
                    <CButton onClick={onClick} disabled={disabled}>
                      {nextButtonLable ?? 'Continuar'}
                    </CButton>
                  )}
                </div>
              )}

              {!noaction && last && (
                <div className="mt-4 d-grid gap-2">
                  <CButton onClick={onClick} disabled={disabled}>
                    {nextButtonLable ?? 'Continuar'}
                  </CButton>
                </div>
              )}
            </>
          )}
        </CContainer>
      )}
    </>
  );
}

Step.propTypes = {
  formStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  nextButtonLable: PropTypes.string,
  onClick: PropTypes.func,
  onBack: PropTypes.func,
  first: PropTypes.bool,
  last: PropTypes.bool,
  noaction: PropTypes.bool,
  disabled: PropTypes.bool,
};
