import React from 'react';
import { PropTypes } from 'prop-types';
import { Select } from '../Select';
import { CButton, CCard, CCloseButton, CModal } from '@coreui/react';
import SuccessIcon from '../../../assets/svg/SuccessIcon.svg';
import AlertIcon from '../../../assets/svg/AlertIcon.svg';

export function ResponseCard({
  isSuccess,
  isVisible,
  errorTitle = 'Erro de Pagamento',
  sucessTitle = 'Pagamento  com sucesso',
  message,
  onSuccess,
  onError,
}) {
  return (
    <CModal alignment="center" className="d-flex flex-column px-4 py-3" visible={isVisible}>
      <div style={{ background: 'red' }}></div>
      <CCard className="d-flex flex-column px-4 py-3">
        <div className="d-flex justify-content-end">
          <CCloseButton onClick={isSuccess ? onSuccess : onError} />
        </div>
        <div className="d-flex justify-content-center flex-column my-5 text-center">
          <img
            style={{ alignSelf: 'center' }}
            src={isSuccess ? SuccessIcon : AlertIcon}
            width={48}
            height={48}
            alt=""
          />
          <h5
            style={{
              color: isSuccess ? '#73D064' : '#EE4B4B',
              marginTop: 15,
              marginBottom: 20,
              fontWeight: 'bold',
            }}
          >
            {isSuccess ? sucessTitle : errorTitle}
          </h5>
          {message}
        </div>
        <CButton
          onClick={isSuccess ? onSuccess : onError}
          style={{ marginBottom: 50, color: 'white' }}
          color={isSuccess ? 'success' : 'danger'}
        >
          {isSuccess ? 'ok' : 'Tente novamente'}
        </CButton>
      </CCard>
    </CModal>
  );
}

//props validation
ResponseCard.propTypes = {
  isSuccess: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  onTryAgain: PropTypes.func,
  onSeeReserve: PropTypes.func,
  sucessTitle: PropTypes.any,
  errorTitle: PropTypes.any,
  message: PropTypes.any,
};
// react display name
Select.displayName = 'ResponseCard';
