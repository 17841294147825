/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { format, parseISO } from 'date-fns';

const ReservationFormContext = createContext();

export const ReservationFormContextProvider = ({ children }) => {
  const [reservationDate, setReservationDate] = useState(null);
  const [referralLink, setReferralLink] = useState('');
  const [errors, setErrors] = useState(null);
  const [step, goToStep] = useState(1);
  const [date, setDate] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [isDateInvalid, setIsDateInvalid] = useState(false);
  const [time, setTime] = useState('12:00');
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);
  const [additionalObservations, setAdditionalObservations] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contact, setContact] = useState('');
  const [reservationTicket, setReservationTicket] = useState('');
  const [price, setPrice] = useState(0);

  let formattedDate;

  const getAddressName = (position) => {
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.lat}&lon=${position.lng}`,
      {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
      .then((response) => response.json())
      .catch((err) => console.log('error in get address name', err));
  };

  useEffect(() => {
    if (date) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      formattedDate = format(parseISO(date), 'dd-MM-yy');
    }

    setReservationDate(formattedDate + ' ' + time);
  }, [time, date]);

  const handleDate = (dateValue) => {
    setDate(dateValue);
    const selectedDate = new Date(`${dateValue} 00:00`);

    const tomorrow = new Date(Date.now() + 86400000);
    tomorrow.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < tomorrow.getTime()) {
      setIsDateInvalid(true);
    } else {
      setIsDateInvalid(false);
    }

    handleTime(time, dateValue);
  };

  const handleTime = (value, localDate = date) => {
    setTime(value);
    if (localDate) {
      const selectedTime = new Date(`${localDate} ${value}`);
      const nowTime = new Date();
      if (selectedTime - nowTime < 86400000) {
        setIsTimeInvalid(true);
      } else {
        setIsTimeInvalid(false);
      }
    }
  };

  const goBack = () => {
    goToStep(step - 1);
  };

  const clearState = async () => {
    setReservationDate(null);
    setErrors(null);
    setDate(null);
    setIsDateInvalid(false);
    setTime('12:00');
    setIsTimeInvalid(false);
    setAdditionalObservations('');
    setEmail('');
    setName('');
    setLastName('');
    setContact('');
    setReservationTicket('');
    setOrigin(null);
    setDestination(null);
    setPrice(0);
  };
  return (
    <ReservationFormContext.Provider
      value={{
        errors,
        date,
        isDateInvalid,
        setIsDateInvalid,
        step,
        goToStep,
        goBack,
        time,
        isTimeInvalid,
        setIsTimeInvalid,
        additionalObservations,
        setAdditionalObservations,
        email,
        setEmail,
        name,
        setName,
        lastName,
        setLastName,
        contact,
        setContact,
        reservationDate,
        handleDate,
        handleTime,
        clearState,
        price,
        setPrice,
        origin,
        setOrigin,
        destination,
        setDestination,
        referralLink,
        setReferralLink,
        getAddressName,
        reservationTicket,
        setReservationTicket,
        setDate,
        setTime,
      }}
    >
      {children}
    </ReservationFormContext.Provider>
  );
};

export function useReservationForm() {
  return useContext(ReservationFormContext);
}

ReservationFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
