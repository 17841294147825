import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ResponseCard } from '../../../components/Reservation/ResponseCard';
import { useReservationForm } from '../../../contexts/ReservationFormContextProvider';
import { usePaymentResponse } from '../../../hooks/Reservation/usePaymentResponse';
import { Map } from '../../../components/Reservation/Map';

export default function ReservationPayment() {
  const {
    setPrice,
    setOrigin,
    setDestination,
    setReservationTicket,
    setContact,
    setName,
    setLastName,
    setEmail,
    setAdditionalObservations,
    setReferralLink,
    setDate,
    setTime,
    goToStep,
  } = useReservationForm();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [loaded, setLoaded] = useState(false);
  const message = searchParams.get('message');
  const isSuccess = searchParams.get('success') === 'true';

  useEffect(() => {
    setPrice(parseInt(searchParams.get('price')));
    setOrigin({
      address: { display_name: searchParams.get('originAddress') },
      latlng: {
        lat: parseFloat(searchParams.get('originLatitude')),
        lng: parseFloat(searchParams.get('originLongitude')),
      },
    });
    setDestination({
      address: { display_name: searchParams.get('destinationAddress') },
      latlng: {
        lat: parseFloat(searchParams.get('destinationLatitude')),
        lng: parseFloat(searchParams.get('destinationLongitude')),
      },
    });
    setReservationTicket(searchParams.get('reservationTicket'));
    setContact(searchParams.get('passengerPhoneNumber'));
    setName(searchParams.get('passengerName'));
    setLastName(searchParams.get('passengerLastName'));
    setEmail(searchParams.get('passengerEmail'));
    setAdditionalObservations(searchParams.get('observation'));
    setReferralLink(searchParams.get('referralLink'));
    setDate(searchParams.get('date'));
    setTime(searchParams.get('time'));
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <Map />
      <ResponseCard
        message={message}
        isVisible={true}
        isSuccess={isSuccess}
        onSuccess={() => {
          history.push('/');
          goToStep(8);
        }}
        onError={() => {
          history.push('/');
          goToStep(4);
        }}
      />
    </>
  );
}
